import React, { useEffect, useState } from 'react';
import { subscriptionremaining } from '../redux/services/otherServices/Users';

const SubscriptionCard = () => {
  const [remainingDays, setRemainingDays] = useState(null); 
  const [expirationMonth, setExpirationMonth] = useState(null); 

  useEffect(() => {
    handleGetRemainingSubscription();
  }, []);

  const handleGetRemainingSubscription = async () => {
    const res = await subscriptionremaining();
    if (res.success === true) {
      const totalDays = res?.response?.body?.remaining_day;
      const { months, days } = convertDaysToMonthsAndDays(totalDays);

      setRemainingDays(totalDays);
      setExpirationMonth(`${months} months and ${days} days`);
    } else {
      setRemainingDays(null); 
      setExpirationMonth(null); 
    }
  };

  const convertDaysToMonthsAndDays = (days) => {
    const averageDaysPerMonth = 30.44;
    const months = Math.floor(days / averageDaysPerMonth);
    const remainingDays = Math.round(days % averageDaysPerMonth);
    return { months, days: remainingDays };
  };

  if (remainingDays === null) {
    return null;
  }

  return (
    <div style={styles.card}>
      <h2>Subscription Status</h2>
      <p>Remaining Days: {remainingDays}</p>
      <p>Expires in: {expirationMonth}</p>
    </div>
  );
};

const styles = {
  card: {
    position: 'absolute',
    top: '100px',
    right: '20px', 
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '300px',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    zIndex: 1000,
  },
};

export default SubscriptionCard;
