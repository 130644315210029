import React from 'react';
import Main from '../components/labdash/chart/Main';


function Labdashboardhome() {

  return (
    <div>
      <Main />
    </div>
  );
}

export default Labdashboardhome;
