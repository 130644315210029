import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Input,
  Typography,
  TablePagination,
  MenuItem,
  Select
} from '@mui/material';
import { appointmentupdate, getalllabsbookinglist, updateStatus } from '../redux/services/otherServices/Lab';
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants/urls';
import SearchIcon from '@mui/icons-material/Search';

function Alllabsbookinglist() {
  const [bookingListData, setBookingListData] = useState([]);
  const [files, setFiles] = useState({});
  const [prices, setPrices] = useState({});
  const [statuses, setStatuses] = useState({});
  const [totalaccounts, setTotalAccounts] = useState("0");
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterApplied, setFilterApplied] = useState(false); // State to track if filter is applied

  useEffect(() => {
    handleGetBookingListData(page, query, startDate, endDate);
  }, [page]);

  const handleSearchInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleGetBookingListData = async (page, query, startDate, endDate) => {
    try {
      const res = await getalllabsbookinglist(page, query, startDate, endDate);
      if (res.success) {
        setBookingListData(res.response?.body?.findAppointment || []);
        setTotalAccounts(res?.response?.body?.totalCount);
      } else {
        toast.error('Failed to fetch booking list.');
      }
    } catch (error) {
      toast.error('An error occurred while fetching the booking list.');
      console.error('Error fetching booking list:', error);
    }
  };

  const handlePriceChange = (id, value) => {
    setPrices(prevPrices => ({
      ...prevPrices,
      [id]: value
    }));
  };

  const handleFileChange = (id, fileType, event) => {
    const file = event.target.files[0];
    setFiles(prevFiles => ({
      ...prevFiles,
      [id]: {
        ...prevFiles[id],
        [fileType]: file
      }
    }));
  };

  const handleStatusChange = async (id, status) => {
    const body = { status };
    try {
      const res = await updateStatus(body, id);
      if (res.success) {
        toast.success('Status updated successfully!');
        handleGetBookingListData(page, query, startDate, endDate);
      } else {
        toast.error('Failed to update status.');
      }
    } catch (error) {
      toast.error('An error occurred while updating status.');
      console.error('Error updating status:', error);
    }
  };

  const handleSubmit = async (id) => {
    const currentPrice = bookingListData.find(item => item.id === id)?.price || "";
    const price = prices[id] || currentPrice;
    const billFile = files[id]?.upload_bill || null;
    const reportFile = files[id]?.upload_report || null;

    const formData = new FormData();
    formData.append('id', id);
    if (price !== "" && price !== null) {
      formData.append('price', price);
    }
    if (billFile) formData.append('upload_bill', billFile);
    if (reportFile) formData.append('upload_report', reportFile);

    try {
      const res = await appointmentupdate(formData, id);
      if (res.success) {
        toast.success('Appointment updated successfully!');
        handleGetBookingListData(page, query, startDate, endDate);
      } else {
        toast.error('Failed to update appointment.');
      }
    } catch (error) {
      toast.error('An error occurred while updating the appointment.');
      console.error('Error updating appointment:', error);
    }
  };

  const downloadFile = (filePath, filename) => {
    const url = `${BASE_URL}${filePath}`;
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const fileExtension = filePath.split('.').pop();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.${fileExtension}`;
        link.click();
      })
      .catch(error => console.error('Download error:', error));
  };

  const handleFilter = () => {
    handleGetBookingListData(page, query, startDate, endDate);
    setFilterApplied(true); // Set filter as applied
  };

  const handleClear = () => {
    setQuery('');
    setStartDate('');
    setEndDate('');
    setFilterApplied(false); // Reset filter applied state
    handleGetBookingListData(page, '', '', ''); // Fetch data without filters
  };

  return (
    <>
      <Typography sx={{ fontWeight: "700" }}>Booking List of Patients</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={query}
          onChange={handleSearchInputChange}
          InputProps={{
            endAdornment: (
              <IconButton size="small">
                <SearchIcon />
              </IconButton>
            ),
            style: { paddingRight: 0 }
          }}
          style={{ width: '300px' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={filterApplied ? handleClear : handleFilter}
          >
            {filterApplied ? 'Clear' : 'Filter'}
          </Button>
        </div>
      </div>

      {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "700" }}>Lab Name</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Patient Name</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Date</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Prescription</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>File</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Price</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Upload Bill</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Upload Report</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingListData.map(row => {
              const isEditable = row.status === 1; // Status 1 means Editable
              const hasAllData = row.price !== null && row.upload_bill !== null && row.upload_report !== null;
              const isReadyToSubmit = isEditable && !hasAllData;
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.lab_name}</TableCell>
                  <TableCell>{row.member_name}</TableCell>
                  <TableCell>{row.appointment_date}</TableCell>
                  <TableCell>{row.prescription || '-'}</TableCell>
                  <TableCell>
                    {row.prescription_image ? (
                      <Button onClick={() => downloadFile(row.prescription_image, 'report')}>Download File</Button>
                      // <img src={ BASE_URL + row.prescription_image} alt="Prescription" style={{ width: 100, height: 100 }} />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditable && row.price === null ? (
                      <TextField
                        type="number"
                        onChange={(e) => handlePriceChange(row.id, e.target.value)}
                        value={prices[row.id] || ''}
                        placeholder="Enter Price"
                      />
                    ) : (
                      row.price || '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditable && row.upload_bill === null ? (
                      <Input
                        type="file"
                        onChange={(e) => handleFileChange(row.id, 'upload_bill', e)}
                      />
                    ) : (
                      row.upload_bill ? (
                        <Button onClick={() => downloadFile(row.upload_bill, 'bill')}>Download Bill</Button>
                      ) : '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditable && row.upload_report === null ? (
                      <Input
                        type="file"
                        onChange={(e) => handleFileChange(row.id, 'upload_report', e)}
                      />
                    ) : (
                      row.upload_report ? (
                        <Button onClick={() => downloadFile(row.upload_report, 'report')}>Download Report</Button>
                      ) : '-'
                    )}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={statuses[row.id] || row.status || 0}
                      onChange={(e) => {
                        const newStatus = e.target.value;
                        setStatuses(prevStatuses => ({
                          ...prevStatuses,
                          [row.id]: newStatus
                        }));
                        handleStatusChange(row.id, newStatus);
                      }}
                      fullWidth
                    >
                      <MenuItem value={0}>Pending</MenuItem>
                      <MenuItem value={1}>Accepted</MenuItem>
                      <MenuItem value={2}>Rejected</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    {isReadyToSubmit ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit(row.id)}
                      >
                        Submit
                      </Button>
                    ) : (
                      isEditable ? 'All Completed' : 'Not Editable'
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
<TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: '700' }}>Lab Name</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Patient Name</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Date</TableCell>
        <TableCell sx={{ fontWeight: '700', whiteSpace: 'normal', wordBreak: 'break-word' }}>Prescription</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>File</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Price</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Upload Bill</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Upload Report</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Status</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {bookingListData.map((row) => {
        const isEditable = row.status === 1; // Status 1 means Editable
        const hasAllData = row.price !== null && row.upload_bill !== null && row.upload_report !== null;
        const isReadyToSubmit = isEditable && !hasAllData;
        return (
          <TableRow key={row.id}>
            <TableCell>{row.lab_name}</TableCell>
            <TableCell>{row.member_name}</TableCell>
            <TableCell>{row.appointment_date}</TableCell>
            <TableCell sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
              {row.prescription || '-'}
            </TableCell>
            <TableCell>
              {row.prescription_image ? (
                <Button onClick={() => downloadFile(row.prescription_image, 'report')}>
                  Download File
                </Button>
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              {isEditable && row.price === null ? (
                <TextField
                  type="number"
                  onChange={(e) => handlePriceChange(row.id, e.target.value)}
                  value={prices[row.id] || ''}
                  placeholder="Enter Price"
                />
              ) : (
                row.price || '-'
              )}
            </TableCell>
            <TableCell>
              {isEditable && row.upload_bill === null ? (
                <Input
                  type="file"
                  onChange={(e) => handleFileChange(row.id, 'upload_bill', e)}
                />
              ) : (
                row.upload_bill ? (
                  <Button onClick={() => downloadFile(row.upload_bill, 'bill')}>
                    Download Bill
                  </Button>
                ) : '-'
              )}
            </TableCell>
            <TableCell>
              {isEditable && row.upload_report === null ? (
                <Input
                  type="file"
                  onChange={(e) => handleFileChange(row.id, 'upload_report', e)}
                />
              ) : (
                row.upload_report ? (
                  <Button onClick={() => downloadFile(row.upload_report, 'report')}>
                    Download Report
                  </Button>
                ) : '-'
              )}
            </TableCell>
            <TableCell>
              <Select
                value={statuses[row.id] || row.status || 0}
                onChange={(e) => {
                  const newStatus = e.target.value;
                  setStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [row.id]: newStatus,
                  }));
                  handleStatusChange(row.id, newStatus);
                }}
                fullWidth
              >
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>Accepted</MenuItem>
                <MenuItem value={2}>Rejected</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              {isReadyToSubmit ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(row.id)}
                >
                  Submit
                </Button>
              ) : (
                isEditable ? 'All Completed' : 'Not Editable'
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>


      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalaccounts}
        rowsPerPage={10}
        page={page}
        onPageChange={(e, newPage) => {
          setPage(newPage);
          handleGetBookingListData(newPage, query, startDate, endDate);
        }}
      />
    </>
  );
}

export default Alllabsbookinglist;
