import React from 'react';
import Body from "../src/homecomponents/Body"
import Header from './homecomponents/Header';
function Home() {
  return (
    <div>
      <Header/>
      <Body/>
    </div>
  );
}

export default Home;
