import React from 'react';
import Main from '../components/agencydash/chart/Main';


function Agencydashboardhome() {
 
  return (
    <div >
<Main/>
      
    </div>
  );
}

export default Agencydashboardhome;
