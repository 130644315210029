import React from 'react';
import Main from '../components/chart/Main';
// import AppView from '../sections/overview/view/app-view';
function Admindashboardhome() {
  return (
    <div >
<Main/>
    </div>
  );
}

export default Admindashboardhome;
