export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";

export const USER_FORGOTPASS_LOADING = "USER_FORGOTPASS_LOADING";
export const USER_FORGOTPASS_SUCCESS = "USER_FORGOTPASS_SUCCESS";
export const USER_FORGOTPASS_ERROR = "USER_FORGOTPASS_ERROR";

export const USER_OTPVERIFY_LOADING = "USER_OTPVERIFY_LOADING";
export const USER_OTPVERIFY_SUCCESS = "USER_OTPVERIFY_SUCCESS";
export const USER_OTPVERIFY_ERROR = "USER_OTPVERIFY_ERROR";

export const USER_RESET_PASSWORD_LOADING = "USER_RESET_PASSWORD_LOADING";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_ERROR = "USER_RESET_PASSWORD_ERROR";

export const USER_ACCOUNT_DETAILS_LOADING = "USER_ACCOUNT_DETAILS_LOADING";
export const USER_ACCOUNT_DETAILS_SUCCESS = "USER_ACCOUNT_DETAILS_SUCCESS";
export const USER_ACCOUNT_DETAILS_ERROR = "USER_ACCOUNT_DETAILS_ERROR";

export const USER_ACCOUNT_UPDATE_LOADING = "USER_ACCOUNT_UPDATE_LOADING";
export const USER_ACCOUNT_UPDATE_SUCCESS = "USER_ACCOUNT_UPDATE_SUCCESS";
export const USER_ACCOUNT_UPDATE_ERROR = "USER_ACCOUNT_UPDATE_ERROR";

export const USER_LOGOUT_LOADING = "USER_LOGOUT_LOADING";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";

export const PUBLIC_KEY_LOADING = "PUBLIC_KEY_LOADING";
export const PUBLIC_KEY_SUCCESS = "PUBLIC_KEY_SUCCESS";
export const PUBLIC_KEY_ERROR = "PUBLIC_KEY_ERROR";
